(function (global, $) {
  function apiCall(url, method, data, headerCustom) {
    const formData = new FormData(data);
    const deferred = $.Deferred();

    $.ajax({
      dataType: 'json',
      url: url,
      data: formData,
      processData: false,
      contentType: false,
      type: method || 'GET',
      async: true,
      headers: headerCustom,
      success: function (returnedData, status, request) {
        deferred.resolve(returnedData);
      },
      error: function (error) {
        if (error.status === 500) {
          //window.location.href = '/500.html';
        } else if (error.status === 404) {
          //window.location.href = '/404.html';
        }

        deferred.reject(error);
      },
      timeout: 30000 // set timeout for 30s
    });

    return deferred.promise();
  }

  function useMockAPI(apiUrl) {
    if (apiUrl.indexOf('https://localhost', 0) === 0)
      return true;
    return false;
  }

  function submitReferralCode(data, url) {
    if (useMockAPI(url))
      return submitReferralCodeMock(data);
    else
      return apiCall(url, 'POST', data);
  }

  function submitReferralCodeMock(formData) {
    const $input = $(formData).find('input[type=text]');
    const defer = $.Deferred();

    setTimeout(function () {
      if ($input.val() === '123') {
        defer.resolve({
          'Status': true,
          'ShowModel': false,
          'ShowPromoModel': false,
          'RedirectUrl': 'electricity.html'
        });
      } else if ($input.val() === '456') {
        defer.resolve({
          'Status': true,
          'ShowModel': true,
          'ShowPromoModel': true,
          'RedirectUrl': 'electricity.html'
        });
      } else
        defer.resolve({
          'Status': false,
          'RedirectUrl': null
        });
    }, 200);
    return defer.promise();
  }

  function submitPromoCode(data, url) {
    if (useMockAPI(url))
      return submitPromoCodeMock(data);
    else
      return apiCall(url, 'POST', data);
  }

  function submitPromoCodeMock(formData) {
    const $input = $(formData).find('input[type=text]');
    const defer = $.Deferred();

    setTimeout(function () {
      if ($input.val() === '123') {
        defer.resolve({
          'Status': true,
          'ShowModel': false,
          'RedirectUrl': 'electricity.html'
        });
      } else if ($input.val() === '456') {
        defer.resolve({
          'Status': true,
          'ShowModel': true,
          'RedirectUrl': 'electricity.html'
        });
      } else if ($input.val() === '789') {
        defer.resolve({
          'Status': false,
          'ShowModel': true,
          'RedirectUrl': 'electricity.html'
        });
      } else
        defer.resolve({
          'Status': false,
          'ShowModel': false,
          'RedirectUrl': null
        });
    }, 200);
    return defer.promise();
  }

  function removePromoCode(data, url) {
      return apiCall(url, 'POST', data);
  }

  // All APIs
  global.apiService = {
    submitReferralCode: submitReferralCode,
    submitPromoCode: submitPromoCode,
    removePromoCode: removePromoCode,
  };
})(window, jQuery);
