$(document).ready(function () {
  let responseUrl = '';
  let showPromoDialog = false;
  const $jsModalSubmitCode = $('.js-modal-submit-code');
  const $jsModalContinuePlan = $('.js-modal-continue');
  const $jsSubmitBtn = $('.electricity').find('.input-check-submit__cta');
  const $jsPlanCheckoutBtn = $('.electricity').find('.js-plan-checkout-button');

  const $confirmRemovingPromoCodeModal = $('.electricity').find('#confirmRemovingPromoCodeModal');
  const $confirmSelectAnotherPlanModal = $('.electricity').find('#confirmChoosingAnotherPlan');

  const hideLoadingSpin = ($self) => {
    const $submitButton = $self.find('.js-submit-button');
    const $imageLoading = $self.find('.js-loading');
    const $jsText = $self.find('.js-text');

    $submitButton.prop('disabled', false);
    $jsPlanCheckoutBtn.removeClass('disabled');
    $imageLoading.removeClass('icon-loading-spinner');
    $jsText.show();
  };

  const showLoadingSpin = ($self) => {
    const $submitButton = $self.find('.js-submit-button');
    const $imageLoading = $self.find('.js-loading');
    const $jsText = $self.find('.js-text');

    $submitButton.attr('disabled', true);
    $jsPlanCheckoutBtn.addClass('disabled');
    $imageLoading.addClass('icon-loading-spinner');
    $jsText.hide();
  };

  const submitReferralCodeFunction = function () {
    const $this = $(this);

    const msgSuccess = $this.find('.js-apply-success-input').val();
    const msgNullInput = $this.find('.js-err-null-input').val();
    const msgErrorServerInput = $this.find('.js-err-server-input').val();
    const msgErrorInvalidReferralCode = $this.find('.js-err-invalid-code').val();

    const $jsNotification = $this.find('.js-input-notification');
    const $jsInputReferralCode = $this.find('.js-referral-code');
    const $jsButtonReferralCode = $this.find('.js-submit-button');
    const $modalForm = $this.find('#referral-submitted-modal');
    const apiUrl = $this.attr('action');

    clearNotificationClass($jsNotification);

    if ($.trim($jsInputReferralCode.val()) === '') {
      $jsInputReferralCode.removeClass('success');
      $jsInputReferralCode.addClass('error');
      displayError($jsNotification, msgNullInput);
      return;
    }

    showLoadingSpin($this);

    window.apiService.submitReferralCode(this, apiUrl).then(function (response) {
      if (response.Status) {
        if (response.ShowModel) {
          $modalForm.modal('show');
          hideLoadingSpin($this);
          responseUrl = response.RedirectUrl;
          showPromoDialog = response.ShowPromoModel;
        } else {
          $jsInputReferralCode.removeClass('error');
          $jsInputReferralCode.addClass('success');
          displaySuccess($jsNotification, msgSuccess, $jsInputReferralCode, $jsButtonReferralCode);
          const $jsPromoCode = $('.electricity').find('.js-submit-promo-code .js-promo-code');
          if ($.trim($jsPromoCode.val()) !== '' && response.ShowPromoModel) {
            $confirmRemovingPromoCodeModal.find('.js-confirm-btn').off('click').on('click', function () {
              window.location.href = response.RedirectUrl;
            });
            $confirmRemovingPromoCodeModal.modal('show');
          } else
            window.location.href = response.RedirectUrl;
        }
      } else {
        $jsInputReferralCode.removeClass('success');
        $jsInputReferralCode.addClass('error');
        displayError($jsNotification, msgErrorInvalidReferralCode);
        hideLoadingSpin($this);
      }
    }).catch(function () {
      $jsInputReferralCode.removeClass('success');
      $jsInputReferralCode.addClass('error');
      displayError($jsNotification, msgErrorServerInput);
      hideLoadingSpin($this);
    });
  };
  const submitHandler = submitReferralCodeFunction.bind($('.js-submit-referral-code')[0]);

  $('.js-submit-referral-code').submit(function (e) {
    e.preventDefault();
    const $this = $(this);
    const msgNullInput = $this.find('.js-err-null-input').val();
    const $jsNotification = $this.find('.js-input-notification');
    const $jsInputReferralCode = $this.find('.js-referral-code');

    if ($.trim($jsInputReferralCode.val()) === '') {
      $jsInputReferralCode.removeClass('success');
      $jsInputReferralCode.addClass('error');
      clearNotificationClass($jsNotification);
      displayError($jsNotification, msgNullInput);
      return;
    }
    submitHandler();
  });

  $('.js-submit-promo-code').submit(function (e) {
    e.preventDefault();
    const $this = $(this);

    const msgSuccess = $this.find('.js-apply-success-input').val();
    const msgNullInput = $this.find('.js-err-null-input').val();
    const msgErrorServerInput = $this.find('.js-err-server-input').val();
    const msgErrorInvalidReferralCode = $this.find('.js-err-invalid-code').val();

    const $jsNotification = $this.find('.js-input-notification');
    const $jsInputReferralCode = $this.find('.js-referral-code');
    const $jsButtonReferralCode = $this.find('.js-submit-button');
    const $modalForm = $this.find('#referral-submitted-modal');
    const apiUrl = $this.attr('action');

    clearNotificationClass($jsNotification);

    if ($.trim($jsInputReferralCode.val()) === '') {
      $jsInputReferralCode.removeClass('success');
      $jsInputReferralCode.addClass('error');
      clearNotificationClass($jsNotification);
      displayError($jsNotification, msgNullInput);
      return;
    }

    showLoadingSpin($this);

    window.apiService.submitPromoCode(this, apiUrl).then(function (response) {
      if (response.Status) {
        if (response.ShowModel) {
          $modalForm.modal('show');
          hideLoadingSpin($this);
          responseUrl = response.RedirectUrl;
        } else {
          $jsInputReferralCode.removeClass('error');
          $jsInputReferralCode.addClass('success');
          displaySuccess($jsNotification, msgSuccess, $jsInputReferralCode, $jsButtonReferralCode);
          window.location.href = response.RedirectUrl;
        }
      } else {
        $jsInputReferralCode.removeClass('success');
        $jsInputReferralCode.addClass('error');
        displayError($jsNotification, msgErrorInvalidReferralCode);
        hideLoadingSpin($this);
      }
    }).catch(function () {
      $jsInputReferralCode.removeClass('success');
      $jsInputReferralCode.addClass('error');
      displayError($jsNotification, msgErrorServerInput);
      hideLoadingSpin($this);
    });
  });

  function clearNotificationClass($notification) {
    $notification.removeClass('error');
    $notification.removeClass('success');
    $notification.html('');
  }

  function displayError($notification, msgError) {
    $notification.addClass('error');
    $notification.text(msgError);
    $notification.show();
  }

  function displaySuccess($notification, msgSuccess, $disabledInput, $disabledButton) {
    $notification.addClass('success');
    $notification.text(msgSuccess);
    $notification.show();
    $disabledInput.prop('disabled', true);
    $disabledButton.prop('disabled', true);
  }

  $jsModalSubmitCode.on('click', function () {
    const $jsPromoCode = $(this).closest('.electricity').find('.js-submit-promo-code .js-promo-code');
    if ($.trim($jsPromoCode.val()) !== '' && showPromoDialog) {
      $confirmRemovingPromoCodeModal.find('.js-confirm-btn').off('click').on('click', function () {
        window.location.href = responseUrl;
      });
      $confirmRemovingPromoCodeModal.modal('show');
    } else
      window.location.href = responseUrl;
  });

  $jsSubmitBtn.on('click', function (event) {
    const $btn = $(event.target);
    const $applyBtnLabel = $btn.closest('.input-check-submit').find('.js-apply-btn-label').val();
    const $successNoti = $($btn.closest('.input-check-submit').find('.input-check-submit__notification'));
    const $input = $($btn.closest('.input-check-submit__input').find('input[type=text]'));
    if ($successNoti.hasClass('success')) {
      $input.prop('disabled', false);
      $btn.text($applyBtnLabel);
      $input.focus();
      $successNoti.removeClass('success');
      $successNoti.html('');
      event.preventDefault();
      const $form = $btn.parents('form')
      if ($form.attr('id') == 'promoCodeForm') {
        window.apiService.removePromoCode($form[0], $form.attr('data-remove-code')).then(function (response) {
          if (response.Status) {
            location.reload();
          }
        });
      }
      if ($form.attr('id') == 'referralForm') {
        window.apiService.removeReferralCode($form[0], $form.attr('data-remove-code')).then(function (response) {
          if (response.Status) {
            location.reload();
          }
        });
      }
    }
    //clear cookies
    let cookieName = 'senoko_' + $input.prop('name');
    document.cookie = cookieName + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  });

  $jsPlanCheckoutBtn.on('click', function (event) {
    const $electricity_component = $($(event.target).closest('.electricity'));
    const $referralInput = $($electricity_component.find('.js-submit-referral-code').find('.js-referral-code'));
    const $promoInput = $($electricity_component.find('.js-submit-promo-code').find('.js-promo-code'));

    const msgReferralEmpty = $electricity_component.find('.js-err-referral').val();
    const msgPromoCodeEmpty = $electricity_component.find('.js-err-promo-code').val();
    const msgBothEmpty = $electricity_component.find('.js-err-both').val();

    const referralNotApplied = $referralInput.length > 0 ? ($referralInput.val() !== '' && (!$referralInput.prop('disabled'))) : false;
    const promoCodeNotApplied = $promoInput.length > 0 ? ($promoInput.val() !== '' && (!$promoInput.prop('disabled'))) : false;

    let errMessage = '';
    if (referralNotApplied && promoCodeNotApplied) {
      errMessage = msgBothEmpty;
    } else if (referralNotApplied) {
      errMessage = msgReferralEmpty;
    } else if (promoCodeNotApplied) {
      errMessage = msgPromoCodeEmpty;
    }

    if (errMessage !== '') {
      const $modal = $($electricity_component.find('#confirmModal'));
      $modal.data('confirm-msg', errMessage);
      $modal.modal('show');
      event.preventDefault();
    }

    if ($(this).hasClass('disabled')) {
      event.preventDefault();
      return false;
    }
  });

  $('.electricity #confirmModal').on('show.bs.modal', function (event) {
    const $modal = $(this);
    $modal.find('.modal-title').text($modal.data('confirm-msg'));
  });

  $('.electricity .js-select-other-plan').on('click', function (event) {
    event.preventDefault();
    const $clickedButton = $(this);
    const $jsPromoCode = $clickedButton.closest('.electricity').find('.js-submit-promo-code .js-promo-code');
    if ($.trim($jsPromoCode.val()) !== '') {
      $confirmSelectAnotherPlanModal.find('.js-confirm-btn').off('click').on('click', function () {
        window.location.href = $clickedButton.attr('href');
      });
      $confirmSelectAnotherPlanModal.modal('show');
    } else {
      window.location.href = $clickedButton.attr('href');
    }
  });


  $jsModalContinuePlan.on('click', function (event) {
    event.preventDefault();
    const $jsCode = $(this).closest('.summary-electricity-plan__check-referral').find('.js-referral-code');
    $jsCode.val('');
    return;
  });
});
