(function() {
  // Code here
  $('form').on('submit', function(event) {
    const $error = $(this).find('.js-error-recaptcha');

    $error.hide();
    if (!window.grecaptcha) {
      return;
    }

    // verify google recaptcha
    const gResponse = window.grecaptcha.getResponse();
    if (!gResponse) {
      $error.show();

      event.preventDefault();
    }
  });
})(window, jQuery);
