$(document).ready(function () {
  if (window.location.search.includes('overlay')) {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('overlay')) {
      const popupModal = '#' + urlParams.get('overlay');
      if (popupModal.length > 1) {
        var $modalElement = $(popupModal);
        if ($modalElement.length)
          $modalElement.modal();
      }
    }
  }
  setTimeout(function () {
    var $modals = $('div.modal');
    if (!$modals.length)
      return;

    $modals.on('shown.bs.modal', function (e) {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('overlay')) {
        urlParams.delete('overlay');
      }
      urlParams.append('overlay', e.target.id);
      ChangeUrl(urlParams);
    })

    $modals.on('hidden.bs.modal', function (e) {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('overlay')) {
        urlParams.delete('overlay');
      }
      ChangeUrl(urlParams);
    })
  }, 1000);

  function ChangeUrl(urlParams) {
    var url = window.location.origin + window.location.pathname;
    if (urlParams.size) {
      url += '?' + urlParams.toString();
    }
    if (typeof(history.pushState) != "undefined") {
      var obj = {
        Url: url
      };
      window.history.pushState(obj, "", obj.Url);
    } else {
      window.location.replace(url);
    }
  }
});
