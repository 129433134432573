(function () {
  // Code here
  $('form').on('submit', function (event) {
    const $form = $(this);
    const $error = $form.find('.js-error-recaptcha');

    $error.hide();
    if (!window.grecaptcha) {
      return;
    }

    // verify google recaptcha
    const $captcha = $form.find('.g-recaptcha');
    if (!$captcha.length) {
      return;
    }
    let target = $captcha.attr('data-target');
    let gResponse = null;
    try {
      if (target && target.length) {
        gResponse = window.grecaptcha.getResponse(window[target]);
      }
      else {
        gResponse = window.grecaptcha.getResponse();
      }
      if (!gResponse) {
        $error.show();
        event.preventDefault();
      }
    }
    catch (err) {
      console.log(err);
      $error.show();
      event.preventDefault();
    }
  });
})(window, jQuery);
